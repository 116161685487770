import Link from 'next/link'
import { Fragment } from 'react'
import { Popover, Transition } from '@headlessui/react'
import {
   MenuIcon,
   XIcon,
} from '@heroicons/react/outline'
import { useAuth } from 'hooks/auth'
import Button from 'components/Button'

const navigation = [
   {
      name: 'Pricing',
      href: '/pricing'
   },
   {
      name: 'Community',
      href: 'https://community.yonderlife.co.uk'
   },
]

function classNames(...classes) {
   return classes.filter(Boolean).join(' ')
}

export default function Header({ fullwidth }) {

   // Access user
   const { user } = useAuth()

   return (
      <Popover className={`relative bg-white ${fullwidth ? 'border-b border-gray-200 shadow-sm' : ''}`}>
         <div className={`px-4 sm:px-6 ${fullwidth ? '' : 'max-w-7xl mx-auto'}`}>
            <div className={`flex justify-between items-center py-6 md:justify-start md:space-x-10 ${fullwidth ? '' : 'border-b-2 border-gray-100'}`}>
               <div className="flex justify-start lg:w-0 lg:flex-1">
                  <a href="/">
                     <span className="sr-only">Yonderlife</span>
                     <img
                        className="h-8 w-auto sm:h-10"
                        src="/logo.svg"
                        alt="Yonderlife logo"
                     />
                  </a>
               </div>
               <div className="-mr-2 -my-2 md:hidden">
                  <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                     <span className="sr-only">Open menu</span>
                     <MenuIcon className="h-6 w-6" aria-hidden="true" />
                  </Popover.Button>
               </div>
               <Popover.Group as="nav" className="hidden md:flex space-x-10">
                  {
                     navigation.map((item) => (
                        <Link key={item.name} href={item.href || '#'}>
                           <a className="text-base font-medium text-gray-500 hover:text-gray-900">
                              {item.name}
                           </a>
                        </Link>
                     ))
                  }
               </Popover.Group>
               <div className="hidden md:flex md:space-x-2 items-center justify-end md:flex-1 lg:w-0">
                  {
                     user ? (
                        <Button href="/dashboard" color="yellow">
                           Dashboard
                        </Button>
                     ) : (
                        <>
                           <Button href="/login" color="yellow">
                              Sign in
                           </Button>
                           <Button href="/register" color="outline">
                              Create your story
                           </Button>
                        </>
                     )
                  }
               </div>
            </div>
         </div>

         <Transition
            as={Fragment}
            enter="duration-200 ease-out"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="duration-100 ease-in"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
         >
            <Popover.Panel focus className="z-20 absolute top-0 inset-x-0 p-2 transition origin-top-right md:hidden">
               <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
                  <div className="pt-5 pb-6 px-5">
                     <div className="flex items-center justify-between">
                        <div>
                           <img
                              className="h-8 w-auto"
                              src="/emblem.svg"
                              alt="Yonderlife"
                           />
                        </div>
                        <div className="-mr-2">
                           <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                              <span className="sr-only">Close menu</span>
                              <XIcon className="h-6 w-6" aria-hidden="true" />
                           </Popover.Button>
                        </div>
                     </div>
                     <div className="mt-10">
                        <nav className="grid gap-y-8">
                           {navigation.map((item) => (
                              <Link key={item.name} href={item.href || '#'}>
                                 <a className="-m-3 p-3 flex items-center rounded-md hover:bg-gray-50">
                                    <span className="ml-3 text-base font-medium text-gray-900">{item.name}</span>
                                 </a>
                              </Link>
                           ))}
                        </nav>
                     </div>
                  </div>
                  <div className="py-6 px-5 space-y-4">
                     {
                        user ? (
                           <Button href="/dashboard" color="yellow" block>
                              Dashboard
                           </Button>
                        ) : (
                           <>
                              <Button href="/login" color="yellow" block>
                                 Sign in
                              </Button>
                              <Button href="/register" color="outline" block>
                                Create your story
                              </Button>
                           </>
                        )
                     }
                  </div>
               </div>
            </Popover.Panel>
         </Transition>
      </Popover>
   )
}
